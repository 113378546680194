'use client'

import * as React from 'react'
import { cva, type VariantProps } from 'class-variance-authority'
import { ArrowRight } from 'lucide-react'

import { cn } from '@/lib/utils'
import { buttonVariants } from '@/components/ui/button'
import { Link } from '@/components/ui/link'

import { ContentfulImage } from './contentful-image'

const ArticleCardContext = React.createContext<{
  variant: 'vertical' | 'horizontal'
}>({ variant: 'vertical' })

const articleCardVariants = cva(
  'relative flex size-full overflow-hidden rounded-xl bg-card-primary shadow-lg',
  {
    variants: {
      variant: {
        vertical: 'flex-col items-start gap-6 pb-6 lg:gap-8 lg:pb-8',
        horizontal:
          'flex-col items-start gap-6 pb-6 sm:flex-row sm:items-stretch sm:pb-0 md:pb-0',
      },
    },
    defaultVariants: {
      variant: 'vertical',
    },
  }
)

interface ArticleCardProps
  extends React.HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof articleCardVariants> {}

const ArticleCard = React.forwardRef<HTMLDivElement, ArticleCardProps>(
  ({ className, variant, ...props }, ref) => (
    <ArticleCardContext.Provider value={{ variant: variant ?? 'vertical' }}>
      <div
        ref={ref}
        className={cn(articleCardVariants({ variant, className }))}
        {...props}
      />
    </ArticleCardContext.Provider>
  )
)
ArticleCard.displayName = 'ArticleCard'

const articleCardImageVariants = cva('relative', {
  variants: {
    variant: {
      vertical: 'aspect-video w-full shrink-0 grow-0',
      horizontal:
        'aspect-video size-full sm:aspect-[4/3] sm:w-[240px] sm:shrink-0 sm:grow-0 xl:aspect-square',
    },
  },
  defaultVariants: {
    variant: 'vertical',
  },
})

interface ArticleCardImageProps extends React.HTMLAttributes<HTMLDivElement> {
  src: string
  alt: string
}

const ArticleCardImage = React.forwardRef<
  HTMLDivElement,
  ArticleCardImageProps
>(({ className, src, ...props }, ref) => {
  const { variant } = React.useContext(ArticleCardContext)

  return (
    <div
      ref={ref}
      className={cn(articleCardImageVariants({ variant, className }))}
      {...props}
    >
      <ContentfulImage
        src={src}
        alt=""
        fill
        className="object-cover"
        sizes="(max-width: 375px) 100vw, (max-width: 768px) 50vw, 33vw"
      />
    </div>
  )
})
ArticleCardImage.displayName = 'ArticleCardImage'

const articleCardContentVariants = cva(
  'flex size-full flex-col items-start justify-between',
  {
    variants: {
      variant: {
        vertical: 'gap-7 px-4 xl:gap-8',
        horizontal: 'gap-7 px-4 sm:gap-8 sm:py-3 md:gap-8',
      },
    },
    defaultVariants: {
      variant: 'vertical',
    },
  }
)

const ArticleCardContent = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => {
  const { variant } = React.useContext(ArticleCardContext)
  return (
    <div
      ref={ref}
      className={cn(articleCardContentVariants({ variant, className }))}
      {...props}
    />
  )
})
ArticleCardContent.displayName = 'ArticleCardContent'

const ArticleCardHeader = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => (
  <div
    ref={ref}
    className={cn(
      'flex size-full flex-col items-start justify-between gap-6',
      className
    )}
    {...props}
  />
))
ArticleCardHeader.displayName = 'ArticleCardHeader'

const ArticleCardHeaderText = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => (
  <div
    ref={ref}
    className={cn('flex w-full flex-col items-start gap-3', className)}
    {...props}
  />
))
ArticleCardHeaderText.displayName = 'ArticleCardHeaderText'

const ArticleCardCategory = React.forwardRef<
  HTMLParagraphElement,
  React.HTMLAttributes<HTMLParagraphElement>
>(({ className, ...props }, ref) => (
  <p
    ref={ref}
    className={cn('body-md-regular text-card-foreground', className)}
    {...props}
  />
))
ArticleCardCategory.displayName = 'ArticleCardCategory'

const ArticleCardTitle = React.forwardRef<
  HTMLHeadingElement,
  React.HTMLAttributes<HTMLHeadingElement>
>(({ className, children, ...props }, ref) => {
  return (
    <h3
      ref={ref}
      className={cn('heading-xs-bold text-card-foreground', className)}
      {...props}
    >
      {children}
    </h3>
  )
})
ArticleCardTitle.displayName = 'ArticleCardTitle'

const ArticleCardTags = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => (
  <div
    ref={ref}
    className={cn('flex w-full flex-wrap gap-x-2 gap-y-3', className)}
    {...props}
  />
))
ArticleCardTags.displayName = 'ArticleCardTags'

interface ArticleCardLinkProps
  extends React.ComponentPropsWithoutRef<typeof Link> {
  children: React.ReactNode
}

const ArticleCardLink = React.forwardRef<
  HTMLAnchorElement,
  ArticleCardLinkProps
>(({ className, children, ...props }, ref) => (
  <Link
    ref={ref}
    className={cn(
      buttonVariants({ variant: 'secondary-link' }),
      'items-center justify-start',
      className
    )}
    {...props}
  >
    {children}
    <ArrowRight className="size-3.5" />
  </Link>
))
ArticleCardLink.displayName = 'ArticleCardLink'

export {
  ArticleCard,
  ArticleCardCategory,
  ArticleCardContent,
  ArticleCardHeader,
  ArticleCardHeaderText,
  ArticleCardImage,
  ArticleCardLink,
  ArticleCardTags,
  ArticleCardTitle,
}
