import React from 'react'
import LinkPrimitive from 'next/link'
import { cva } from 'class-variance-authority'

import { cn } from '@/lib/utils'

const linkVariants = cva(
  'focus-visible:underline focus-visible:shadow-ring focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-accessibility-ring active:underline disabled:pointer-events-none',
  {
    variants: {
      variant: { default: '', 'subcategory-badge': 'rounded-lg' },
    },
  }
)

const Link = React.forwardRef<
  React.ElementRef<typeof LinkPrimitive>,
  React.ComponentPropsWithoutRef<typeof LinkPrimitive>
>(({ className, href, ...props }, ref) => {
  const base = process.env.NEXT_PUBLIC_BASE_URL || ''
  const isValidUrl = base.startsWith('http') || base.startsWith('https')
  const isExternal =
    isValidUrl && !new URL(href.toString(), base).href.startsWith(base ?? '')
  return (
    <LinkPrimitive
      className={cn(linkVariants(), className)}
      href={href || ''}
      ref={ref}
      {...(isExternal && {
        target: '_blank',
        rel: 'noopener noreferrer',
      })}
      {...props}
    />
  )
})
Link.displayName = 'Link'

export { Link, linkVariants }
